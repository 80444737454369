import {
  add,
  differenceInMinutes,
  endOfDay,
  format,
  parse,
  startOfDay,
  startOfWeek,
  sub,
} from 'date-fns';
import { YEAR_WEEK_FORMAT, YEAR_MONTH_DAY_FORMAT } from './consts';

export const getStartAndEndDates = (
  seedDate: Date,
): [startDate: Date, endDate: Date] => {
  const startDate = startOfDay(seedDate);
  const endDate = endOfDay(seedDate);
  return [startDate, endDate];
};

export const convertToTargetTimezone = (date: Date, targetTz: string): Date =>
  new Date(date.toLocaleString('en-US', { timeZone: targetTz }));

// get start date in utc based on the target timezone
export const toUTCStartOfDay = (date: Date, targetTz: string) => {
  const tzDate = convertToTargetTimezone(date, targetTz);
  const targetDate = add(date, {
    minutes: -differenceInMinutes(date, tzDate),
  });
  return add(startOfDay(targetDate), {
    minutes: -date.getTimezoneOffset(),
  });
};

export type DateRange = {
  dateStart: Date;
  dateEnd: Date;
};

/**
 * Generates an array of past dates in the format 'YYYYMMDD' starting from a given start date.
 *
 * @param {Date} startDate - The start date for generating dates.
 * @param {number} [numberOfDays=7] - The number of dates to generate (default is 7).
 * @param {string} [dateFormat=yyyyMMdd] - The format of dates (default is yyMMdd, e.g. 20231203).
 * @returns {string[]} An array of dates in the format 'YYYYMMDD'.
 */

export const generateDateRange = (
  startDate: Date,
  numberOfDays = 7,
  dateFormat = YEAR_MONTH_DAY_FORMAT,
): string[] => {
  const dates: string[] = [];
  /**
   * If you know, that format from date-fns reads date and time parts from the date instance in the local time zone,
   * you will need to make your date "looking like" the midnight in your local time zone and not in UTC,
   * which you passed to the Date constructor. Then you will see the year, month and date numbers preserved.
   * It means, that you need to subtract the time zone offset of your local time zone for the specified day.
   * Date.prototype.getTimezoneOffset returns the offset, but with an inverted sign and in minutes.
   */
  const dateWithTZ = new Date(
    startDate.valueOf() + startDate.getTimezoneOffset() * 60 * 1000,
  );

  for (let i = 0; i < numberOfDays; i += 1) {
    const formattedDate = format(dateWithTZ, dateFormat);
    dates.push(formattedDate);

    dateWithTZ.setDate(dateWithTZ.getDate() + 1); // Move to the next day
  }

  return dates;
};

export const getStartOfPreviousWeekFromUTCTime = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const utcDate = add(date, { minutes: offset });
  return startOfWeek(sub(utcDate, { weeks: 1 }));
};

export const parseYearWeekFormatToDate = (id: string): Date =>
  parse(id, YEAR_WEEK_FORMAT, new Date(), {
    useAdditionalWeekYearTokens: true,
  });

// see @https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md for more information
export const getYearWeekFormat = (date: Date): string =>
  format(date, YEAR_WEEK_FORMAT, {
    useAdditionalWeekYearTokens: true,
  });

export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
