import React from 'react';

// Thumbnails
import AgentGuideThumbnailImage from './thumbnails/agent_guide_thumbnail.png';
import ClientGuideThumbnailImage from './thumbnails/client_guide_thumbnail.png';

export { ReactComponent as LogoIcon } from './icons/logo.svg';
export { ReactComponent as LogoDevIcon } from './icons/logo_dev.svg';
export { ReactComponent as MoreHorizontalIcon } from './icons/ic_more_horiz.svg';
export { ReactComponent as EyeIcon } from './icons/eyeIcon.svg';

export { ReactComponent as ArrowDownIcon } from './icons/ic_arrow_drop_down.svg';
export { ReactComponent as GreenCheckIcon } from './icons/greenCheckIcon.svg';
export { ReactComponent as AnnouncementIcon } from './icons/announcementIcon.svg';
export { ReactComponent as ClientWizardCloseIcon } from './icons/ic_close.svg';
export { ReactComponent as QuestionMarkIcon } from './icons/ic_help.svg';
export { ReactComponent as ThreeDotIcon } from './icons/Group_173.svg';
export { ReactComponent as AddIcon } from './icons/ic_add.svg';
export { ReactComponent as MinusIcon } from './icons/ic_minus.svg';
export { ReactComponent as RemoveIcon } from './icons/Group124.svg';
export { ReactComponent as TickIcon } from './icons/ic_check.svg';

export { ReactComponent as ErrorIcon } from './icons/Group_124.svg';
export { ReactComponent as RoundErrorIcon } from './icons/Group124.svg';
export { ReactComponent as PercentageIcon } from './icons/percentage.svg';
export { ReactComponent as EditIcon } from './icons/ic_create.svg';
export { ReactComponent as EventIcon } from './icons/ic_event.svg';
export { ReactComponent as CancelIcon } from './icons/ic_cancel.svg';
export { ReactComponent as UsersIconSmall } from './icons/ic_group_16px.svg';
export { ReactComponent as shiftsIcon } from './icons/ic_shifts.svg';
export { ReactComponent as ImportIcon } from './icons/ic_import.svg';
export { ReactComponent as ExportIcon } from './icons/ic_export.svg';
export { ReactComponent as PencilEditIcon } from './icons/ic_penedit.svg';

// AGENTS
export { ReactComponent as CheckIconDone } from './icons/Group_257.svg';
export { ReactComponent as DollarIcon } from './icons/ic_dollar.svg';
export { ReactComponent as PencilIcon } from './icons/ic_pencil.svg';
export { ReactComponent as PencilOutlineIcon } from './icons/ic_pencile_outline.svg';
export { ReactComponent as TrashIcon } from './icons/ic_trash.svg';
export { ReactComponent as TimeIcon } from './icons/Time.svg';
export { ReactComponent as CheckBoardIcon } from './icons/Checkboard.svg';
export { ReactComponent as SoundIcon } from './icons/Sound.svg';
export { ReactComponent as CopyIcon } from './icons/ic_copy.svg';

// Insights
export { ReactComponent as RocketIcon } from './icons/ic_rocket.svg';

// Earnings
export { ReactComponent as MoneyIcon } from './icons/ic_money.svg';
export { ReactComponent as MoneyIconWithdraw } from './icons/ic_monney_withdraw.svg';
export { ReactComponent as WithdrawOnceIcon } from './icons/ic_withdraw_once.svg';
export { ReactComponent as WithdrawDaysIcon } from './icons/ic_withdraw_days.svg';

// Client Dashboard V2
export { ReactComponent as ActiveAgentIcon } from './icons/ic_active_agent.svg';
export { ReactComponent as ActiveAgentThreeStarsIcon } from './icons/ic_active_agent_above_3_stars.svg';
export { ReactComponent as ScheduleAdherenceIcon } from './icons/ic_schedule_adherence.svg';
export { ReactComponent as ContactCountIcon } from './icons/ic_contact_count.svg';
export { ReactComponent as PlatformMinutesIcon } from './icons/ic_platform_minutes.svg';
export { ReactComponent as PlatformSpendIcon } from './icons/ic_platform_spend.svg';

// Wallet V2
export { ReactComponent as LogoInWhiteIcon } from './icons/ic_logo_white.svg';
export { ReactComponent as EditIconV2 } from './icons/ic_edit.svg';
export { ReactComponent as RepeatIcon } from './icons/ic_repeat.svg';
export { ReactComponent as ClockIconInWhite } from './icons/ic_clock.svg';
export { ReactComponent as WithdrawTimesIcon } from './icons/ic_withdraw_times.svg';
export { ReactComponent as WithdrawFeeIcon } from './icons/ic_withdraw_fee.svg';

export { ReactComponent as InfoIcon } from './icons/infoIcon.svg';

export {
  AimOutlined,
  NotificationOutlined,
  LogoutOutlined,
  SettingFilled,
  ArrowLeftOutlined,
  MoreOutlined,
  SearchOutlined,
  PlusOutlined,
  UserOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

export * from './icons';

export const AgentGuideThumbnail = AgentGuideThumbnailImage;
export const ClientGuideThumbnail = ClientGuideThumbnailImage;

export type Icon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
