import { MouseEventHandler } from 'react';
import { ReactComponent as ArrowDropDownIcon } from '../../assets/icons/base/ic_arrow_drop_down.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/base/ic_arrow_down.svg';
import { ReactComponent as BtnCloseIcon } from '../../assets/icons/base/ic_btn_close.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/base/ic_arrow_left.svg';
import { ReactComponent as ArrowRigtIcon } from '../../assets/icons/base/ic_arrow_right.svg';
import { ReactComponent as MoreHorizontalIcon } from '../../assets/icons/base/ic_more_horiz.svg';
import { ReactComponent as EditTableIcon } from '../../assets/icons/base/ic_table_edit.svg';
import { ReactComponent as TableTrashIcon } from '../../assets/icons/base/ic_table_trash.svg';
import { ReactComponent as ApprovedIcon } from '../../assets/icons/base/ic_approved.svg';
import { ReactComponent as PendingIcon } from '../../assets/icons/base/ic_pending.svg';
import { ReactComponent as AppliedIcon } from '../../assets/icons/base/ic_applied.svg';
import { ReactComponent as TrainingIcon } from '../../assets/icons/base/ic_training.svg';
import { ReactComponent as RemovedIcon } from '../../assets/icons/base/ic_removed.svg';
import { ReactComponent as UnionIcon } from '../../assets/icons/base/ic_union.svg';
import { ReactComponent as CheckSquareIcon } from '../../assets/icons/base/ic_check_square.svg';
import { ReactComponent as ResetIcon } from '../../assets/icons/base/ic_reset.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/base/ic_edit.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/base/ic_plus_circle.svg';
import { ReactComponent as CameraOffIcon } from '../../assets/icons/base/ic_camera_off.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/base/ic_plus.svg';
import { ReactComponent as SubtractIcon } from '../../assets/icons/base/ic_sub.svg';
import { ReactComponent as MultiplyIcon } from '../../assets/icons/base/ic_multi.svg';
import { ReactComponent as DivideIcon } from '../../assets/icons/base/ic_divide.svg';
import { ReactComponent as HelpCircleIcon } from '../../assets/icons/base/help_circle.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/base/ic_calendar.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/base/ic_schedule.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/base/ic_user-filled.svg';
import { ReactComponent as ButtonPreviousIcon } from '../../assets/icons/base/ic_btn_previous.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/base/infoIcon.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/base/star.svg';
import { ReactComponent as LoadingIcon } from '../../assets/icons/base/ic_loading.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/base/ic_copy.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/base/ic_chevron-right.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/base/ic_help.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/base/ic_play.svg';
import { ReactComponent as AngelDoubleRightIcon } from '../../assets/icons/base/ic_angle_double_check.svg';
import { ReactComponent as NoMonitorIcon } from '../../assets/icons/base/ic_no_monitor.svg';
import { ReactComponent as CAFlagIcon } from '../../assets/icons/base/ic_flag_ca.svg';
import { ReactComponent as USFlagIcon } from '../../assets/icons/base/ic_flag_us.svg';
import { ReactComponent as PHFlagIcon } from '../../assets/icons/base/ic_flag_ph.svg';
import { ReactComponent as MXFlagIcon } from '../../assets/icons/base/ic_flag_mx.svg';
import { ReactComponent as AlertIcon } from '../../assets/icons/base/ic_alert.svg';
import { ReactComponent as UserCheckIcon } from '../../assets/icons/base/ic_user_check.svg';
import { ReactComponent as ScheduleCalendarIcon } from '../../assets/icons/base/ic_schedule_calendar.svg';
import { ReactComponent as SlashIcon } from '../../assets/icons/base/ic_slash.svg';
import { ReactComponent as ZapIcon } from '../../assets/icons/base/ic_zap.svg';
import { ReactComponent as MedalIcon } from '../../assets/icons/base/ic_medal.svg';
import { ReactComponent as PartyIcon } from '../../assets/icons/base/ic_party.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/base/ic_search.svg';
import { ReactComponent as AngryFaceIcon } from '../../assets/icons/base/angry_face.svg';
import { ReactComponent as HappyFaceIcon } from '../../assets/icons/base/happy_face.svg';
import { ReactComponent as HourGlassClockIcon } from '../../assets/icons/base/hourglass_clock.svg';
import { ReactComponent as MehFaceIcon } from '../../assets/icons/base/meh_face.svg';
import { ReactComponent as StarFilledIcon } from '../../assets/icons/base/star_filled.svg';
import { ReactComponent as RocketIcon } from '../../assets/icons/base/ic_rocket.svg';
import { ReactComponent as FrownIcon } from '../../assets/icons/base/ic_frown.svg';
import { ReactComponent as MehIcon } from '../../assets/icons/base/ic_meh.svg';
import { ReactComponent as SmileIcon } from '../../assets/icons/base/ic_smile.svg';
import { ReactComponent as FlagMarkIcon } from '../../assets/icons/base/ic_flag_mark.svg';
import { ReactComponent as PhoneOffIcon } from '../../assets/icons/base/ic_phone_off.svg';
import { ReactComponent as StreakRankIcon } from '../../assets/icons/base/ic_streak_rank.svg';
import { ReactComponent as MascotIcon } from '../../assets/icons/base/ic_mascot.svg';
import { ReactComponent as FileBookIcon } from '../../assets/icons/base/file_book.svg';
import { ReactComponent as FileCheckIcon } from '../../assets/icons/base/file_check.svg';
import { ReactComponent as FileDotsIcon } from '../../assets/icons/base/file_dots.svg';
import { ReactComponent as FIleXIcon } from '../../assets/icons/base/file_x.svg';
import { ReactComponent as LaptopIcon } from '../../assets/icons/base/laptop.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/base/file.svg';
import { ReactComponent as BriefCaseIcon } from '../../assets/icons/base/ic_briefcase.svg';
import { ReactComponent as AwardIcon } from '../../assets/icons/base/ic_award.svg';
import { ReactComponent as FolderCheckIcon } from '../../assets/icons/base/ic_folder_check.svg';
import { ReactComponent as ToolIcon } from '../../assets/icons/base/ic_tool.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/base/refresh.svg';
import { ReactComponent as LoaderIcon } from '../../assets/icons/base/loader.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/base/check.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/base/download.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/base/ic_upload.svg';
import { ReactComponent as FileDownloadIcon } from '../../assets/icons/base/ic_file_downloaded.svg';
import { ReactComponent as DollarIcon } from '../../assets/icons/base/ic_dollar.svg';
import { ReactComponent as CoinStackIcon } from '../../assets/icons/base/ic_coin_stack.svg';
import { ReactComponent as SendIcon } from '../../assets/icons/base/ic_send.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/base/trash.svg';
import { ReactComponent as DotsVerticalIcon } from '../../assets/icons/base/dots_vertical.svg';
import { ReactComponent as GlobeIcon } from '../../assets/icons/base/globe.svg';
import { ReactComponent as Building02Icon } from '../../assets/icons/base/building_02.svg';
import { ReactComponent as FolderMinusIcon } from '../../assets/icons/base/ic_folder_minus.svg';

import { BaseIconType, BaseIconTypes } from './baseIcon.types';

export type BaseIconProps = {
  icon: BaseIconType;
  color?: string;
  stroke?: string;
  size?: number;
  className?: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  dataTestId?: string;
  name?: string;
  rotate?: number;
};

export const BaseIconComponentMaps = {
  [BaseIconTypes.arrowDropDown]: ArrowDropDownIcon,
  [BaseIconTypes.arrowDown]: ArrowDownIcon,
  [BaseIconTypes.btnClose]: BtnCloseIcon,
  [BaseIconTypes.arrowLeft]: ArrowLeftIcon,
  [BaseIconTypes.arrowRight]: ArrowRigtIcon,
  [BaseIconTypes.moreHorizontal]: MoreHorizontalIcon,
  [BaseIconTypes.tableEdit]: EditTableIcon,
  [BaseIconTypes.tableTrash]: TableTrashIcon,
  [BaseIconTypes.trash]: TrashIcon,
  [BaseIconTypes.approved]: ApprovedIcon,
  [BaseIconTypes.pending]: PendingIcon,
  [BaseIconTypes.applied]: AppliedIcon,
  [BaseIconTypes.training]: TrainingIcon,
  [BaseIconTypes.removed]: RemovedIcon,
  [BaseIconTypes.union]: UnionIcon,
  [BaseIconTypes.checkSquare]: CheckSquareIcon,
  [BaseIconTypes.reset]: ResetIcon,
  [BaseIconTypes.edit]: EditIcon,
  [BaseIconTypes.add]: AddIcon,
  [BaseIconTypes.cameraOff]: CameraOffIcon,
  [BaseIconTypes.plus]: PlusIcon,
  [BaseIconTypes.subtract]: SubtractIcon,
  [BaseIconTypes.multiply]: MultiplyIcon,
  [BaseIconTypes.divide]: DivideIcon,
  [BaseIconTypes.helpCircle]: HelpCircleIcon,
  [BaseIconTypes.calendar]: CalendarIcon,
  [BaseIconTypes.clock]: ClockIcon,
  [BaseIconTypes.user]: UserIcon,
  [BaseIconTypes.buttonPrevious]: ButtonPreviousIcon,
  [BaseIconTypes.info]: InfoIcon,
  [BaseIconTypes.star]: StarIcon,
  [BaseIconTypes.loading]: LoadingIcon,
  [BaseIconTypes.copy]: CopyIcon,
  [BaseIconTypes.help]: HelpIcon,
  [BaseIconTypes.play]: PlayIcon,
  [BaseIconTypes.chevronRight]: ChevronRightIcon,
  [BaseIconTypes.angleDoubleRight]: AngelDoubleRightIcon,
  [BaseIconTypes.noMonitor]: NoMonitorIcon,
  [BaseIconTypes.caFlag]: CAFlagIcon,
  [BaseIconTypes.usFlag]: USFlagIcon,
  [BaseIconTypes.mxFlag]: MXFlagIcon,
  [BaseIconTypes.phFlag]: PHFlagIcon,
  [BaseIconTypes.alert]: AlertIcon,
  [BaseIconTypes.userCheck]: UserCheckIcon,
  [BaseIconTypes.scheduleCalendar]: ScheduleCalendarIcon,
  [BaseIconTypes.slash]: SlashIcon,
  [BaseIconTypes.zap]: ZapIcon,
  [BaseIconTypes.medal]: MedalIcon,
  [BaseIconTypes.party]: PartyIcon,
  [BaseIconTypes.search]: SearchIcon,
  [BaseIconTypes.angryFace]: AngryFaceIcon,
  [BaseIconTypes.happyFace]: HappyFaceIcon,
  [BaseIconTypes.hourglassClock]: HourGlassClockIcon,
  [BaseIconTypes.mehFace]: MehFaceIcon,
  [BaseIconTypes.starFilled]: StarFilledIcon,
  [BaseIconTypes.rocket]: RocketIcon,
  [BaseIconTypes.smile]: SmileIcon,
  [BaseIconTypes.meh]: MehIcon,
  [BaseIconTypes.frown]: FrownIcon,
  [BaseIconTypes.flagMark]: FlagMarkIcon,
  [BaseIconTypes.phoneOff]: PhoneOffIcon,
  [BaseIconTypes.streakRank]: StreakRankIcon,
  [BaseIconTypes.mascot]: MascotIcon,
  [BaseIconTypes.fileBook]: FileBookIcon,
  [BaseIconTypes.fileCheck]: FileCheckIcon,
  [BaseIconTypes.fileDots]: FileDotsIcon,
  [BaseIconTypes.fileX]: FIleXIcon,
  [BaseIconTypes.laptop]: LaptopIcon,
  [BaseIconTypes.file]: FileIcon,
  [BaseIconTypes.briefCase]: BriefCaseIcon,
  [BaseIconTypes.award]: AwardIcon,
  [BaseIconTypes.folderCheck]: FolderCheckIcon,
  [BaseIconTypes.tool]: ToolIcon,
  [BaseIconTypes.refresh]: RefreshIcon,
  [BaseIconTypes.loader]: LoaderIcon,
  [BaseIconTypes.check]: CheckIcon,
  [BaseIconTypes.download]: DownloadIcon,
  [BaseIconTypes.upload]: UploadIcon,
  [BaseIconTypes.fileDownload]: FileDownloadIcon,
  [BaseIconTypes.dollar]: DollarIcon,
  [BaseIconTypes.coinStack]: CoinStackIcon,
  [BaseIconTypes.send]: SendIcon,
  [BaseIconTypes.dotsVertical]: DotsVerticalIcon,
  [BaseIconTypes.globe]: GlobeIcon,
  [BaseIconTypes.building02]: Building02Icon,
  [BaseIconTypes.folderMinus]: FolderMinusIcon,
};
