import React, {
  useState,
  useCallback,
  ChangeEvent,
  MouseEvent,
  FocusEventHandler,
  ReactNode,
  Ref,
  KeyboardEventHandler,
  CSSProperties,
} from 'react';
import FormItem from 'shared/ui/components/FormItem';
import type { InputRef } from 'antd';
import InputLabel from '../InputLabel';
import {
  StyledInput,
  StyledPasswordInput,
  PasswordVisibilityHiddenButton,
} from './styledComponents';

enum LabelPosition {
  Top,
  Bottom,
}

export type InputProps = {
  onChange?: (value: string) => void;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  label?: ReactNode;
  error?: string;
  isPassword?: boolean;
  RightLabelComponent?: JSX.Element;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string;
  placeholder?: string;
  labelPosition?: LabelPosition;
  bordered?: boolean;
  refs?: Ref<InputRef>;
  onKeyDown?: KeyboardEventHandler;
  color?: string;
  style?: CSSProperties;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  className?: string;
  name?: string;
  allowClear?: boolean;
};

// TODO: revise the entire input logic, w/ <FormInput /> if we have a chance...

const Input = React.forwardRef<InputRef, InputProps>(
  (
    {
      label,
      isPassword,
      RightLabelComponent,
      onChange,
      error,
      disabled,
      value,
      placeholder,
      labelPosition,
      readOnly,
      suffix,
      prefix,
      name,
      // need this because to combine Field and Ant component
      // https://stackoverflow.com/a/45514030/5799742
      ...rest
    },
    ref,
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const onInputChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) =>
        onChange && onChange(e.target.value),
      [onChange],
    );

    const handleOnClick = useCallback(
      (e: MouseEvent<HTMLInputElement>) => {
        if (readOnly) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
      [readOnly],
    );

    const showLabelOnTop = !(
      labelPosition && labelPosition === LabelPosition.Bottom
    );

    const labelComponent = (
      <InputLabel
        label={label}
        htmlFor={name}
        RightLabelComponent={RightLabelComponent}
      />
    );

    return (
      <div>
        {showLabelOnTop && labelComponent}
        <FormItem
          validateStatus={error ? 'error' : ''}
          help={error || undefined}
        >
          {isPassword ? (
            <>
              <StyledPasswordInput
                id={name}
                {...rest}
                onChange={onInputChange}
                disabled={disabled}
                value={value}
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
              />
              <PasswordVisibilityHiddenButton
                type="button"
                onClick={() => setPasswordVisible((prevState) => !prevState)}
              >
                &nbsp;
              </PasswordVisibilityHiddenButton>
            </>
          ) : (
            <StyledInput
              id={name}
              {...rest}
              value={value}
              onMouseDown={handleOnClick}
              onChange={onInputChange}
              disabled={disabled}
              placeholder={placeholder}
              readOnly={readOnly}
              ref={ref}
              suffix={suffix}
              prefix={prefix}
            />
          )}
          {showLabelOnTop || labelComponent}
        </FormItem>
      </div>
    );
  },
);

export default React.memo(Input);
